<script setup lang="ts">
import type { Promotion, HowWorks } from "@/types";
const { open, close } = useNlcModals();
const { t } = useT();
const isGuest = useIsGuest();

const props = defineProps<{
	title: string;
	type?: Promotion["type"];
	image: string;
	howWorks: HowWorks;
	coins: number;
	entries: number;
	available: boolean;
	closeModal?: boolean;
	referralUrl?: string;
}>();

const handleClickInfo = () => {
	if (!props.howWorks?.cardHowItWorks) {
		return;
	}

	return open("LazyOModalPromoHowItWorks", { howWorks: props.howWorks, type: props.type });
};

const handleClick = () => {
	if (props.referralUrl) {
		open("LazyOModalInviteFriends", { referralUrl: props.referralUrl });
	}

	dispatchGAEvent({ event: "click_button", location: "promotions", buttonName: "copy" });
};
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="title"
		:image="image"
		:buttonName="t('Share a link')"
		buttonIcon="16/share"
		:buttonInfo="!isGuest"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<template #top>
			<span v-if="closeModal" class="btn-close" @click.stop="close('LazyOModalPromoInviteFriends')">
				<NuxtIcon name="20/close" />
			</span>
		</template>
		<MPromotionCoins :type="type" :coins="coins" :entries="entries" />
		<AText :modifiers="['bold']">
			{{ available ? t("Refer your friend & Get rewarded") : t("A reward for each qualified Friend!") }}
		</AText>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
:deep(.box-coins) {
	margin-bottom: 32px;
}

.btn-close {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 16px;
	right: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	color: var(--neutral-100);
	background: var(--custom-rgb-5);

	.nuxt-icon {
		font-size: 12px;
	}

	&:deep(+ .btn-info) {
		display: none;
	}
}
</style>
